@import "colors";
@import "mixin";

.lnk-purple {
  text-decoration: none;
  color:$white;
  display: inline-block;
  background-color:$c-purple;
  padding:10px;
  width:100px;
  @include border-radius(20px);
  @extend .trstn;
  @extend .bsbb;
  &:hover {
    background-color:$white;
    color:$c-purple;
    @extend .trstn;
  }
}

.lnk-circle {
  @extend .lnk-purple;
  width:50px;
  height: 50px;
  padding:10px;
  @include border-radius(50%);
  line-height: 30px;
}