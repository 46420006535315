@import "colors";
@import "mixin";

html, body {
  width: 100%;
  height:100%;
  margin: 0;
  padding: 0;
  //background-color:$white;
  //overflow: auto;
  /*background:url('../files/mt-1141-home-pattern.png');
  background-position: fixed;
  background-size:cover;*/
}

body, div, input, textarea, pre {
  color: $grey-900;
  font-size: 20px;
  font-family: 'Dosis', sans-serif;
  vertical-align: top;
  font-weight: 400;
}

h1 {
  font-family: 'Norican', cursive;
  text-transform: capitalize;
  margin:0;
  padding: 0;
  font-size: 50px;
}


.input-txt {
  height: 40px;
  @extend .bsbb;
  padding:5px;
}

textarea {
  resize: none;
  height: 100px;
}

textarea:focus, input:focus{
  outline: none;
}

a:active, a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}