@import '_colors';
@import '_mixin';

.padd {
  @extend .bsbb;
  text-align: center;
  @media screen and (min-width: 320px) {
    padding: 50px 10px;
  }
  @media screen and (min-width: 480px) {
    padding: 50px 20px;
  }
  @media screen and (min-width: 768px) {
    padding: 50px;
  }
  @media screen and (min-width: 1024px) {
    padding: 50px;
  }
  @media screen and (min-width: 1200px) {
    padding: 50px;
  }
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
}

.show {
  /*position: relative;
  padding:300px 0;
  text-align: center;
  display: block;
  height: 100%;
  width:100%;
  background-color:rgba($grey-900, 0.5);*/
  color: $white;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  padding: 300px 0;
  background-color: rgba($grey-900, 0.5);
  text-align: center;
  @media screen and (min-width: 320px) {
    font-size: 80px;
  }
  @media screen and (min-width: 480px) {
    font-size: 100px;
  }
  @media screen and (min-width: 768px) {
    font-size: 100px;
  }
  font-family: 'Norican', cursive;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../files/show.jpg') no-repeat center center;
    background-size: cover;
    z-index: -1;
    /*content: '';
    position: absolute;
    top:0;
    left:0;
    @extend .bsbb;
    //padding:300px 0;
    //height: 100%;
    //@include calc("height", "100% + 300px");
    height: 100%;
    width: 100%;
    //display: block;
    background: url("../files/show.jpg") no-repeat center center;
    background-size: cover;
    z-index: -1;*/
  }
  .path {
    font-size: 50px;
  }
}

.counter {
  @extend .padd;
  background: url('../files/mt-1141-home-pattern.png');
  background-color: rgba($c-counter, 1);
  span {
    font-size: 50px;
  }
  .iban {
    @media screen and (min-width: 320px) {
      font-size: 25px;
    }
    @media screen and (min-width: 480px) {
      font-size: 30px;
    }
    @media screen and (min-width: 768px) {
      font-size: 40px;
    }
  }
}

.sep {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  @extend .bsbb;
  margin: 20px 0;
  height: 30px;
  background: url(../files/mt-1141-home-line1.png) no-repeat center center;
  background-size: auto 100%;
}

.program {
  @extend .padd;
  background: url('../files/mt-1141-home-pattern.png');
  background-color: rgba($grey-300, 1);
  ul {
    width: 200px;
    text-align: left;
  }
  iframe {
    width: 80%;
    max-width: 400px;
  }
}

.list {
  @extend .padd;
}

footer {
  @extend .padd;
  background: url('../files/mt-1141-home-pattern.png');
  background-color: rgba($grey-300, 1);
}

.tag {
  font-family: 'Norican', cursive;
  @media screen and (min-width: 320px) {
    font-size: 30px;
  }
  @media screen and (min-width: 768px) {
    font-size: 50px;
  }
}

.images {
  @extend .padd;
  background: url('../files/mt-1141-home-pattern.png');
  background-color: rgba($c-counter, 1);
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  @media screen and (min-width: 320px) {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  img {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    width: 200px;
    &:nth-child(3n + 1) {
      -ms-transform: rotate(7deg);
      /* IE 9 */
      -webkit-transform: rotate(7deg);
      /* Chrome, Safari, Opera */
      transform: rotate(7deg);
    }
    &:nth-child(3n + 2) {
      -ms-transform: rotate(-4deg);
      /* IE 9 */
      -webkit-transform: rotate(-4deg);
      /* Chrome, Safari, Opera */
      transform: rotate(-4deg);
    }
    &:nth-child(3n) {
      -ms-transform: rotate(2deg);
      /* IE 9 */
      -webkit-transform: rotate(2deg);
      /* Chrome, Safari, Opera */
      transform: rotate(2deg);
    }
  }
}

.write {
  @media screen and (min-width: 320px) {
    width: 300px;
  }
  @media screen and (min-width: 480px) {
    width: 400px;
  }
  @media screen and (min-width: 768px) {
    width: 500px;
  }
  @extend .bsbb;
  padding: 0 20px;
  .top {
    content: '';
    height: 45px;
    background: url('../files/mt-1141-home-contact-form-bg1.png') no-repeat
      bottom center;
    background-size: 100%;
  }
  form {
    background: url('../files/mt-1141-home-contact-form-bg3.png');
    background-size: 100%;
    .rsp {
      @extend .bsbb;
      width: 100%;
      padding: 5px 40px;
      color: $green;
    }
  }
  .bottom {
    content: '';
    height: 45px;
    background: url('../files/mt-1141-home-contact-form-bg2.png') no-repeat top
      center;
    background-size: 100%;
  }
  input,
  textarea {
    @media screen and (min-width: 320px) {
      width: 200px;
    }
    @media screen and (min-width: 480px) {
      width: 250px;
    }
    @media screen and (min-width: 768px) {
      width: 300px;
    }
    margin-bottom: 10px;
    padding: 10px; //@include border-radius(20px);
    border: 0;
    border-bottom: 1px solid $grey-300;
    &.error {
      border-bottom: 1px solid $red;
    }
  }
}

.heart {
  img {
    width: 200px;
  }
}

.list-images {
  // @extend .responsive-width;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-wrap;
  margin-top: 20px;
  @extend .flex-center;
  div {
    margin: 2px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 150%;
      object-fit: contain;
    }
  }
}

.lightBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @extend .bsbb;
  @extend .backdrop;
  padding: 5%;
  z-index: $zi-lightbox;
  @extend .bsbb;
  @extend .display-flex;
  @extend .flex-center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
